import {Stack, Typography} from "@mui/material";
import PersonData from "./PersonData";
import {FormattedMessage} from "react-intl";


const RoomData = ({nameOfRoom, persons, setter, settable}) => {
    const normalBeds = persons.filter(p => !p.on_spare)
    const spareBeds = persons.filter(p => p.on_spare)

    return <div className='room-data'>
        <Typography gutterBottom variant='h5' color='#000'>{nameOfRoom}</Typography>
        <Stack spacing={3}>
            {normalBeds.map((p, i) => <span key={i}>
                <Typography variant='caption' color='#000'>{i+1}. <FormattedMessage id='res.person'/></Typography>
                <PersonData person={p} setter={setter} settable={settable}/>
            </span>)}
            {spareBeds.length !== 0 ? <Typography variant='body' color='#000'><FormattedMessage id='res.spareBeds'/></Typography> : <></>}
            {spareBeds.map((p, i) => <span key={i}>
                <Typography variant='caption' color='#000'>{i+1}. <FormattedMessage id='res.person'/></Typography>
                <PersonData person={p} setter={setter} settable={settable}/>
            </span>)}
        </Stack>
    </div>
}

export default RoomData