import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import {FormattedMessage} from "react-intl";
import ConfigContext from "../context/ConfigContext";
import {Autocomplete, Stack, TextField} from "@mui/material";
import LanguageContext from "../context/LanguageContext";
import countries from "../utils/countries";


function ResponsiveAppBar({firstName}) {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const {logoutUser} = useContext(AuthContext)
    const {saveFunc, submitFunc} = useContext(ConfigContext)
    const {lang, setLang} = useContext(LanguageContext)


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const getOptionLabel = (option) => {
        return option?.lang ? option.lang : option;
    }

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}}}>
                        <Button
                            onClick={() => saveFunc.current.click()}
                            sx={{my: 2, background: 'white', display: 'block', mr: 2}}
                        >
                            <FormattedMessage id='res.save'/>
                        </Button>
                        <Button
                            onClick={() => submitFunc.current.click()}
                            sx={{my: 2, background: 'white', display: 'block'}}
                        >
                            <FormattedMessage id='res.submit'/>
                        </Button>
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Stack direction='row' spacing={2}>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{width: '100%'}}
                                options={countries}
                                autoHighlight
                                value={lang}
                                onChange={(event, newValue) => {
                                    setLang(newValue?.lang ? newValue.lang : 'EN');
                                }}
                                isOptionEqualToValue={(option, value) => option.lang === value}
                                getOptionLabel={(option) => getOptionLabel(option)}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                        />
                                        {option.lang}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={firstName} src="/static/images/avatar/2.jpg"/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={logoutUser}>
                                <Typography textAlign="center"><FormattedMessage id='login.logout'/></Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;