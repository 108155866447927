import {createContext, useRef, useState} from "react";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {
    const saveFunc = useRef(null)
    const submitFunc = useRef(null)

    const contextData = {
        // baseUrl: process.env.REACT_APP_BASE_URL,
        baseUrl: 'https://checkinadmin.imolaplatan.hu',
        saveFunc: saveFunc,
        submitFunc: submitFunc
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}