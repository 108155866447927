import {createContext, useState, useCallback, useContext} from "react";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import {FormattedMessage, useIntl} from "react-intl";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const urlParams = window.location.href.split('/')
    const {baseUrl} = useContext(ConfigContext)
    const [code, setCode] = useState(() => localStorage.getItem("code") || undefined);
    const [slug, setSlug] = useState(() => urlParams[urlParams.length - 1] || undefined);

    const intl = useIntl()

    const history = useHistory();

    const loginUser = async (e) => {
        e.preventDefault();
        const s = e.target.slug.value
        const c = e.target.code.value
        const response = await fetch(`${baseUrl}/reservations/check/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({code: c, slug: s}),
        });

        if (response.status === 200) {
            setCode(c)
            setSlug(s)
            localStorage.setItem("code", c);
            history.push(`/reservation/${s}`);
        } else if (response.status === 204) {
            alert(intl.formatMessage({id: 'auth.filled'}));
        } else {
            alert(intl.formatMessage({id: 'auth.unexpected'}));
        }
    };


    const logoutUser = useCallback(() => {
        localStorage.removeItem("code");
        history.push(`/login/${slug}`);
    }, [history]);

    const contextData = {
        // user: user,
        code: code,
        slug: slug,
        loginUser: loginUser,
        logoutUser: logoutUser,
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};