import {createContext, useRef, useState} from "react";

const LanguageContext = createContext();

export default LanguageContext;

export const LanguageProvider = ({ children }) => {
    const knownLangs = ['HU', 'DE', 'EN']

    const initialState = knownLangs.includes(navigator.language) ? navigator.language : 'EN';

    const [lang, setLang] = useState(initialState)

    const contextData = {
        lang: lang,
        setLang: setLang
    }

    return <LanguageContext.Provider value={contextData}>{children}</LanguageContext.Provider>
}