import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./typography";
import {components} from "./componentDef";
import {lightGreen} from "@mui/material/colors";


// const dinoGreen = {
//     '900': '#09120D',
//     '800': '#5D9D3D',
//     '700': '#2F5C43',
//     'A700': '#2F5C43',
//     '600': '#478A64',
//     '500': '#5EB885',
//     '400': '#7EC69D',
//     'A400': '#7EC69D',
//     '300': '#BFCE3B',
//     '200': '#BFE3CE',
//     'A200': '#BFE3CE',
//     '100': '#DFF1E7',
//     'A100': '#DFF1E7',
//     '50': '#EFF8F3',
//     '20': '#F4FAF7'
// }


const greenTheme = createTheme({
        components: components,
        name: 'green',
        palette: {
            primary: lightGreen,
            secondary: lightGreen,
        },
        typography: typography
    },
    huHU
)

export default greenTheme