import ReservationData from "../components/ReservationData";
import ResponsiveAppBar from "../components/ImolaAppBar";

const ReservationPage = ({data}) => {
    return <>
        <ResponsiveAppBar firstName={data?.first_name}/>
        <div className='center-container'>
            <ReservationData
                rooms={data?.connections}
                reservedName={`${data?.first_name} ${data?.last_name}`}
                endDate={data?.end_date}
                fromDate={data?.from_date}
            />
        </div>
    </>
}

export default ReservationPage