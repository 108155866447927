import {Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useEffect, useState} from "react";
import countries from "./countries";
import {format} from "date-fns";
import {hu} from "date-fns/locale";
import {FormattedMessage, useIntl} from "react-intl";


const PersonData = ({person, setter, settable}) => {

    const [editedData, setEditedData] = useState(person)

    const intl = useIntl()

    useEffect(() => {
        const asd = editedData.id
        setter({...settable, [asd]: editedData})
    }, [editedData])

    return <div className='person-data'>
        <Stack spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={5}><TextField value={editedData.first_name} onChange={e => setEditedData({...editedData, first_name: e.target.value})} fullWidth label={<FormattedMessage id='person.fName'/>} required/></Grid>
                <Grid item xs={5}><TextField value={editedData.last_name} onChange={e => setEditedData({...editedData, last_name: e.target.value})} fullWidth label={<FormattedMessage id='person.lName'/>} required/></Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"><FormattedMessage id='person.gender'/></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age" required
                            value={editedData.gender} onChange={e => setEditedData({...editedData, gender: e.target.value})}
                        >
                            <MenuItem value='male'><FormattedMessage id='person.male'/></MenuItem>
                            <MenuItem value='female'><FormattedMessage id='person.female'/></MenuItem>
                            <MenuItem value='-'><FormattedMessage id='person.unknown'/></MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            {/*</Grid>*/}
            {/*<Grid container spacing={2}>*/}
                <Grid item xs={3}>
                    <Autocomplete
                        id="country-select-demo"
                        fullWidth
                        xs={{height: '100%'}}
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => option?.label || option}
                        isOptionEqualToValue={(option, value) => option.label === value}
                        value={editedData.nationality}
                        onChange={(e, v) => setEditedData({...editedData, nationality: v.label})}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label} ({option.code})
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<FormattedMessage id='person.country'/>}
                                fullWidth
                                xs={{height: '100%'}}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField value={editedData.address} onChange={e => setEditedData({...editedData, address: e.target.value})} fullWidth label={<FormattedMessage id='person.address'/>} required/>
                </Grid>

                <Grid item xs={6}><TextField value={editedData.email} onChange={e => setEditedData({...editedData, email: e.target.value})} label={<FormattedMessage id='person.email'/>} type='email' fullWidth required/></Grid>
                <Grid item xs={6}><TextField value={editedData.phone} onChange={e => setEditedData({...editedData, phone: e.target.value})} label={<FormattedMessage id='person.phone'/>} fullWidth/></Grid>


                <Grid item xs={6}><TextField value={editedData.place_of_birth} onChange={e => setEditedData({...editedData, place_of_birth: e.target.value})} label={<FormattedMessage id='person.birthplace'/>} fullWidth required/></Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={editedData.date_of_birth}
                            onChange={(newDate) => {
                                let dateOfBirth = ''
                                try {
                                    dateOfBirth = format(newDate.$d, 'yyyy-MM-dd', {locale: hu});
                                } catch (e) {
                                    dateOfBirth = newDate
                                }
                                setEditedData({...editedData, date_of_birth: dateOfBirth})
                            }}
                            openTo='year'
                            required

                            maxDate={new Date()}
                            inputFormat="YYYY-MM-DD"
                            renderInput={(params) => <TextField name='date_of_birth' label={<FormattedMessage id='person.birthdate'/>} fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}><TextField value={editedData.id_number} onChange={e => setEditedData({...editedData, id_number: e.target.value})} label={<FormattedMessage id='person.id'/>} fullWidth required/></Grid>
                <Grid item xs={6}><TextField value={editedData.plate} onChange={e => setEditedData({...editedData, plate: e.target.value})} label={<FormattedMessage id='person.plate'/>} fullWidth/></Grid>
            </Grid>
            <TextField value={editedData.comment}
                       onChange={e => setEditedData({...editedData, comment: e.target.value})}
                       multiline minRows={4} placeholder={intl.formatMessage({id: 'person.comment'})}/>

        </Stack>
    </div>
}

export default PersonData