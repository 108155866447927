import './App.css';
import {ThemeProvider} from "@mui/material";
import greenTheme from "./components/theme/imolaTheme";

import LoginPage from "./pages/Login";
import ReservationPage from "./pages/ReservationPage";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import PrivateRoute from "./utils/PrivateRoute";
import {IntlProvider} from 'react-intl';
import ENGLISH from './lang/en.json'
import HUNGARIAN from './lang/hu.json'
import GERMAN from './lang/de.json'
import {useContext} from "react";
import LanguageContext from "./context/LanguageContext";

function App() {
    const {lang} = useContext(LanguageContext)

    const languages = {
        'en-US': ENGLISH,
        'en-GB': ENGLISH,
        'GB': ENGLISH,
        'DE': GERMAN,
        'HU': HUNGARIAN
    }


    return (
        <ThemeProvider theme={greenTheme}>
            <IntlProvider locale={lang} messages={languages[lang] || ENGLISH}>
                <ConfigProvider>
                    <Router>
                        <AuthProvider>
                            <Switch>
                                <Route path='/login/:slug' component={LoginPage} exact/>
                                <PrivateRoute path='/reservation/:slug' component={ReservationPage} exact/>
                            </Switch>
                        </AuthProvider>
                    </Router>
                </ConfigProvider>
            </IntlProvider>
        </ThemeProvider>
    );
}

export default App;
