import imolaLogo from '../assets/imaola.gif'
import {Button, Stack, TextField, Typography} from "@mui/material";
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import {useParams} from "react-router-dom";
import {FormattedMessage} from 'react-intl';

const Login = () => {
    const {loginUser} = useContext(AuthContext)
    const {slug} = useParams()


    return <div className='login-card'>
        <Stack spacing={2}>
            <div className="logo-container">
                <img src={imolaLogo} alt="imola logo" className='login-logo'/>
            </div>

            <Typography variant='h6' alignSelf='center'>
                <FormattedMessage id='login.heading'/>
            </Typography>

            <Typography variant='body' alignSelf='center'>
                <FormattedMessage id='login.description'/>
            </Typography>
            <form onSubmit={loginUser}>
                <Stack spacing={2}>
                    <TextField label={<FormattedMessage id='login.code'/>} name='code'/>
                    <input type="hidden" name='slug' value={slug}/>
                    <Button variant='contained' type="submit"><FormattedMessage id='login.button'/></Button>
                </Stack>
            </form>
        </Stack>
    </div>
}

export default Login