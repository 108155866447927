import {Button, Stack, Typography} from "@mui/material";
import RoomData from "./RoomData";
import imolaLogo from '../assets/imaola.gif'
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthContext";
import ConfigContext from "../context/ConfigContext";
import {useHistory, useParams} from "react-router-dom";
import {FormattedMessage, useIntl} from 'react-intl';

const ReservationData = () => {
    const [reservationData, setReservationData] = useState({})
    const [data, setData] = useState({})
    const {code} = useContext(AuthContext)
    const {baseUrl, saveFunc, submitFunc} = useContext(ConfigContext)
    const {slug} = useParams()
    const history = useHistory()
    const intl = useIntl()

    const getData = () => {
        fetch(`${baseUrl}/reservations/serialized/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({code: code, slug: slug})
        })
            .then(r => {
                if (r.status === 200) {
                    r.json().then(r => setReservationData(r))
                } else {
                    alert(intl.formatMessage({id: 'auth.incorrect'}))
                }
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const saveReservationData = () => {
        fetch(`${baseUrl}/reservations/fill_data/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...data, code: code, slug: slug})
        })
            .then(r => {
                if (r.status === 200) {
                    alert(intl.formatMessage({id: 'auth.saved'}))
                } else {
                    alert(intl.formatMessage({id: 'auth.unexpected'}))
                }
            })
    }

    const submitReservationData = () => {
        fetch(`${baseUrl}/reservations/submit_data/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...data, code: code, slug: slug})
        })
            .then(r => {
                if (r.status === 200) {
                    alert(intl.formatMessage({id: 'auth.submitted'}))
                    history.push(`/login/${slug}`)
                } else {
                    r.json().then(data => alert(data.message))

                }
            })
    }

    return <div className='reservation-data'>
        <div className="logo-container">
            <img src={imolaLogo} alt="imola logo" className='login-logo'/>
        </div>
        <Typography variant='h6' textAlign='center' gutterBottom>{reservationData?.last_name + ' ' + reservationData?.first_name} • {reservationData?.from_date} - {reservationData?.end_date}</Typography>
        <Typography variant='body' textAlign='justify' ><FormattedMessage id='res.description'/></Typography>
        {reservationData?.connections?.map((r, k) =>
            <span key={k}>
                <RoomData nameOfRoom={r.room} persons={r.connection} setter={setData} settable={data}/>
          </span>
        )}
        <Stack spacing={2} marginTop={2}>
            <Button variant='contained' ref={saveFunc} onClick={saveReservationData}><FormattedMessage
                id='res.save'/></Button>
            <Button variant='contained' ref={submitFunc} onClick={submitReservationData}><FormattedMessage
                id='res.submit'/></Button>
        </Stack>
    </div>
}

export default ReservationData